import './index.scss'

function ProjectCard({ title, photo, desc, projLink }) {
  return (
    <>
      <a target="_blank" rel="noreferrer" href={projLink}>
        <div className="card-main">
          <img className="card-img" src={photo} alt="" />
          <h1 className="card-title">{title}</h1>
          <p className="card-desc">{desc}</p>
        </div>
      </a>
    </>
  )
}

export default ProjectCard
