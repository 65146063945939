import './index.scss'
import Loader from 'react-loaders'
import ProjectCard from '../ProjectCard'
import gittingSocial from '../../assets/images/gittingSocial.png'
import sanity from '../../assets/images/sanity.png'
import redditClone from '../../assets/images/redditClone.png'
import twitterClone from '../../assets/images/twitterClone.png'
import graphql from '../../assets/images/icons8-graphql.svg'
import typeScript from '../../assets/images/icons8-typescript.svg'
import tailWindCss from '../../assets/images/icons8-tailwind-css.svg'
import postgreSQL from '../../assets/images/icons8-postgresql.svg'
import nextJs from '../../assets/images/icons8-next.js.svg'
import fireBase from '../../assets/images/icons8-google-firebase-console.svg'
import materialUi from '../../assets/images/icons8-material-ui.svg'
import gitHub from '../../assets/images/icons8-github.svg'
import {
  faCss3,
  faHtml5,
  faJsSquare,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Portfolio = () => {
  return (
    <>
      <div className="container portfolio-page">
        <div className="no">
          <div className="project">
            <div className="cardTools">
              <ProjectCard
                projLink="https://reddit-clone-green-iota.vercel.app/"
                title="Reddit Clone"
                desc="A web app inspired by Reddit. This web app allows you to sign in with your reddit account using Nextauth authentication, post subreddits, comment on other users post and Upvote posts."
                photo={redditClone}
              />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/XUnknownX11/reddit-clone.git"
                className="svgTool"
              >
                <img className="iconSvg" src={gitHub} alt="" /> Source Code
              </a>
              <div className="IconTool">
                <FontAwesomeIcon icon={faReact} color="#5ED3F3" /> Reactjs
              </div>
              <div className="svgTool">
                <img className="iconSvg" src={tailWindCss} alt="" /> TailWind
              </div>
              <div className="IconTool">
                <FontAwesomeIcon icon={faHtml5} color="#D84924" /> HTML5
              </div>
              <div className="svgTool">
                <img className="iconSvg" src={typeScript} alt="" /> TypeScript
              </div>
              <div className="svgTool">
                <img className="iconSvg" src={postgreSQL} alt="" /> PostgreSQL
              </div>
              <div className="svgTool">
                <img className="iconSvg" src={graphql} alt="" /> Graphql
              </div>
              <div className="svgTool">
                <img className="iconSvg" src={nextJs} alt="" /> Next.JS
              </div>
            </div>
            <div className="cardTools">
              <ProjectCard
                projLink="https://gittingsocial.com/"
                title="Gitting Social"
                desc="A web app inspired by Facebook. This web app allows you to sign in with your google account using Firebase authentication. It also allows you to post messages in and get real-time updates with Firebase real-time database."
                photo={gittingSocial}
              />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/XUnknownX11/gitting-social.git"
                className="svgTool"
              >
                <img className="iconSvg" src={gitHub} alt="" /> Source Code
              </a>
              <div className="IconTool">
                <FontAwesomeIcon icon={faReact} color="#5ED3F3" /> Reactjs
              </div>
              <div className="IconTool">
                <FontAwesomeIcon icon={faCss3} color="#1F8EE7" /> CSS
              </div>
              <div className="IconTool">
                <FontAwesomeIcon icon={faHtml5} color="#D84924" /> HTML5
              </div>
              <div className="IconTool">
                <FontAwesomeIcon icon={faJsSquare} color="#EAD41C" /> JavaScript
              </div>
              <div className="svgTool">
                <img className="iconSvg" src={fireBase} alt="" /> FireBase
              </div>
              <div className="svgTool">
                <img className="iconSvg" src={materialUi} alt="" /> Material UI
              </div>
            </div>
            <div className="cardTools">
              <ProjectCard
                projLink="https://twitter-clone-brown.vercel.app/"
                title="Twitter Clone"
                desc="A web app inspired by Twitter. This web app allows you to sign in with your Twitter account using Nextauth authentication. You can tweet and comment on others tweets "
                photo={twitterClone}
              />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/XUnknownX11/twitter-clone.git"
                className="svgTool"
              >
                <img className="iconSvg" src={gitHub} alt="" /> Source Code
              </a>
              <div className="IconTool">
                <FontAwesomeIcon icon={faReact} color="#5ED3F3" /> Reactjs
              </div>
              <div className="svgTool">
                <img className="iconSvg" src={tailWindCss} alt="" /> TailWind
              </div>
              <div className="IconTool">
                <FontAwesomeIcon icon={faHtml5} color="#D84924" /> HTML5
              </div>
              <div className="svgTool">
                <img className="iconSvg" src={typeScript} alt="" /> TypeScript
              </div>
              <div className="svgTool">
                <img className="iconSvg" src={nextJs} alt="" /> Next.JS
              </div>
              <div className="svgTool">
                <img className="iconImg" src={sanity} alt="" /> SANITY
              </div>
            </div>
          </div>
        </div>

        <Loader type="pacman" />
      </div>
    </>
  )
}

export default Portfolio
